/* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Luckiest+Guy&family=Manrope:wght@500;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Luckiest+Guy&family=Manrope:wght@500;700&family=Space+Grotesk:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500&family=Inter:wght@400;600;700&family=Luckiest+Guy&family=Manrope:wght@500;700&family=Montserrat:ital,wght@0,200;0,500;1,400&family=Space+Grotesk:wght@400;700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}