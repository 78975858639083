@import url(https://fonts.googleapis.com/css?family=Roboto:300);

.login-page {
  width: 100%;
  /* padding: 8% 0 0; */
  margin: auto;
}
.form {
  position: relative;
  /* z-index: 1; */
  /* background: #FFFFFF; */
  width: 100%;
  /* margin: 0 auto 100px; */
  /* padding: 45px; */
  text-align: center;
  
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 18px;
}
.form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #fc2b5a;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 18px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}



